<template>
  <Input
    v-bind="$attrs"
    v-on="$listeners"
    :type="showPassword ? 'text' : 'password'"
    :value="value"
  />
</template>

<script>
import Input from './Input';

export default {
  name: 'InputPassword',
  extends: Input,
  props: {
    showPassword: {
      type: Boolean,
      default: false
    }
  },
}
</script>
